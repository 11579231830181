import { Device } from '@capacitor/device'

//https://gist.github.com/adamawolf/3048717
const devices = {
    'iPhone SE (1st generation)': ['iPhone8,4'],
    'iPhone 7': ['iPhone9,1', 'iPhone9,3'],
    'iPhone 7 Plus': ['iPhone9,2', 'iPhone9,4'],
    'iPhone 8': ['iPhone10,1', 'iPhone10,4'],
    'iPhone 8 Plus': ['iPhone10,2', 'iPhone10,5'],
    'iPhone X': ['iPhone10,3', 'iPhone10,6'],
    'iPhone XR': ['iPhone11,8'],
    'iPhone XS': ['iPhone11,2'],
    'iPhone XS Max': ['iPhone11,6', 'iPhone11,4'],
    'iPhone 11': ['iPhone12,1'],
    'iPhone 11 Pro': ['iPhone12,3'],
    'iPhone 11 Pro Max': ['iPhone12,5'],
    'iPhone SE (2nd generation)': ['iPhone12,8'],
    'iPhone 12 Mini': ['iPhone13,1'],
    'iPhone 12': ['iPhone13,2'],
    'iPhone 12 Pro': ['iPhone13,3'],
    'iPhone 12 Pro Max': ['iPhone13,4'],
    'iPhone 13 Pro': ['iPhone14,2'],
    'iPhone 13 Pro Max': ['iPhone14,3'],
    'iPhone 13 Mini': ['iPhone14,4'],
    'iPhone 13': ['iPhone14,5'],
    'iPhone 14': ['iPhone14,7'],
    'iPhone 14 Plus': ['iPhone14,8'],
    'iPhone 14 Pro': ['iPhone15,2'],
    'iPhone 14 Pro Max': ['iPhone15,3'],
    'iPhone 15': ['iPhone15,4'],
    'iPhone 15 Plus': ['iPhone15,5'],
    'iPhone 15 Pro': ['iPhone16,1'],
    'iPhone 15 Pro Max': ['iPhone16,2'],
}

const codeNames = (devs) => devs.flatMap((deviceId) => devices[deviceId] ?? [])

// Devices that don't have a navigation home button.
const noHomeButtonDevices = codeNames([
    'iPhone X',
    'iPhone XR',
    'iPhone XS',
    'iPhone XS Max',
    'iPhone 11',
    'iPhone 11 Pro',
    'iPhone 11 Pro Max',
    'iPhone 12 Mini',
    'iPhone 12',
    'iPhone 12 Pro',
    'iPhone 12 Pro Max',
    'iPhone 13 Pro',
    'iPhone 13 Pro Max',
    'iPhone 13 Mini',
    'iPhone 13',
    'iPhone 14',
    'iPhone 14 Plus',
    'iPhone 14 Pro',
    'iPhone 14 Pro Max',
    'iPhone 15',
    'iPhone 15 Plus',
    'iPhone 15 Pro',
    'iPhone 15 Pro Max',
])
// Devices that are iOS and have a big notch.
const bigNotchDevices = codeNames([
    'iPhone X',
    'iPhone XR',
    'iPhone XS',
    'iPhone XS Max',
    'iPhone 11',
    'iPhone 11 Pro',
    'iPhone 11 Pro Max',
    'iPhone 12 Mini',
    'iPhone 12',
    'iPhone 12 Pro',
    'iPhone 12 Pro Max',
    'iPhone 13 Pro',
    'iPhone 13 Pro Max',
    'iPhone 13 Mini',
    'iPhone 13',
    'iPhone 14',
    'iPhone 14 Plus',
    'iPhone 14 Pro',
    'iPhone 14 Pro Max',
    'iPhone 15',
    'iPhone 15 Plus',
    'iPhone 15 Pro',
    'iPhone 15 Pro Max',
])

export default {
    namespaced: true,
    state: () => {
        return {
            /**
             * Stores the device information
             * that the user is using.
             */
            info: undefined,
            cordova_info: undefined,
        }
    },

    getters: {
        topPadding(state) {
            if (state.info === undefined || state.info.platform !== 'ios' || state.cordova_info === undefined)
                return 'none'
            return bigNotchDevices.includes(state.cordova_info.model) ? 'big' : 'small'
        },
        bottomPadding(state) {
            if (state.info === undefined || state.info.platform !== 'ios' || state.cordova_info === undefined)
                return 'none'
            return noHomeButtonDevices.includes(state.cordova_info.model) ? 'small' : 'none'
        },
    },

    mutations: {
        /**
         * Sets the device information.
         */
        info(state, info) {
            state.info = info
        },
        cordova_info(state, info) {
            state.cordova_info = info
        },
    },

    actions: {
        /**
         * Loads the device information
         * info the state.
         */
        async load({ commit }) {
            commit('info', await Device.getInfo())
        },
    },
}
