import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import axios from '@/lib/API/client'
import { format } from '@/lib/helpers/time'

export default () => {
    const store = useStore()
    const user = reactive({
        first_name: '',
        last_name: '',
        birth_at: '',
        email: '',
        phone: '',
        country: '',
        bio: '',
        no_fei: '',
        no_national: '',
        job_name: '',
        gender: '',
        size: '',
        weight: '',
        step_length: '',
        avatar: '',
        profile: '',
        temperature_unit: '',
        length_unit: '',
        weight_unit: '',
        speed_unit: '',
        locale: '',
        upload_count: '',
        beta: '',
    })

    const setUser = (userData = {}) => {
        user.first_name = userData?.first_name || ''
        user.last_name = userData?.last_name || ''
        user.birth_at = userData?.birth_at ? format(userData.birth_at.replace('Z', ''), 'YYYY-MM-DD') : ''
        user.email = userData?.email || ''
        user.phone = userData?.phone || ''
        user.country = userData?.country || ''
        user.bio = userData?.bio || ''
        user.no_fei = userData?.no_fei || ''
        user.no_national = userData?.no_national || ''
        user.job_name = userData?.job_name || ''
        user.gender = userData?.gender || ''
        user.size = userData?.size || ''
        user.weight = userData?.weight || ''
        user.step_length = userData?.step_length || ''
        user.avatar = userData?.avatar || ''
        user.profile = userData?.profile || 0
        user.temperature_unit = userData?.temperature_unit || 'C'
        user.length_unit = userData?.length_unit || 'm'
        user.weight_unit = userData?.weight_unit || 'kg'
        user.speed_unit = userData?.speed_unit || 'm/min'
        user.locale = userData?.locale || ''
        user.upload_count = userData?.upload_count || 0
        user.beta = userData?.beta || 0
    }

    const userUnits = computed(() => {
        return {
            distance: user.length_unit,
            weight: user.weight_unit,
            speed: user.speed_unit,
            temperature: user.temperature_unit,
            time: 'relative', //relative or absolute
        }
    })

    const uploadCount = computed(() => user.upload_count)

    setUser(store.state.auth.user)

    const refreshUser = () => {
        return store.dispatch('auth/user', user).then((user) => {
            setUser(user)
            return user
        })
    }

    const updateUser = () => {
        return store.dispatch('auth/edit', user).then((response) => {
            setUser(response.data)
            return response.data
        })
    }

    const deleteUser = () => {
        return axios.delete('/user', user).then((response) => {
            return response.data
        })
    }

    const updateAvatar = (file) => {
        let formData = new FormData()
        formData.append('avatar', file)

        return axios
            .post('/user/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                setUser(response.data)
                store.dispatch('auth/setUser', response.data)
                return response.data
            })
    }

    return { user, userUnits, uploadCount, refreshUser, updateUser, updateAvatar, deleteUser }
}
