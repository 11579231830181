<template>
    <div class="flex flex-row items-center justify-center space-x-4">
        <div class="flex flex-col text-center space-y-1">
            <!-- <Avatar :avatar="horse.avatar" :read-only="true" /> -->
            <Avatar :avatar="horse.avatar" :read-only="readOnly" @update:avatar="updateAvatar" :small="small" />

            <div v-if="owners && displayOwners" class="flex items-center justify-center text-center">
                <div class="w-fit px-2 py-1 text-white bg-dark-light text-xs tracking-widest leading-4 rounded-full">
                    <TooltipElement class="flex items-center justify-center text-center">
                        {{ $t('horse.owner', owners.split('/').length) }}: {{ owners }}
                        <template #tooltip>
                            {{ $t('team.are-you-owner') }}
                            <a
                                :href="'mailto:support@alogo-analysis.ch?subject=Horse #' + horse.id"
                                class="text-brand-base"
                            >
                                support@alogo-analysis.ch.
                            </a>
                        </template>
                    </TooltipElement>
                </div>
            </div>

            <template v-if="displayOwners">
                <div class="flex items-center justify-center text-center">
                    <div
                        class="w-fit px-2 py-1 flex items-center justify-center text-center text-white bg-dark-light text-xs tracking-widest leading-4 rounded-full space-x-2"
                    >
                        <div>{{ $t('user.job.vet', owners.split('/').length) }}:</div>
                        <div v-if="veterinarians">{{ veterinarians }}</div>
                        <div v-else>
                            <AddLink :action="() => (openAddVetModal = true)" />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="flex flex-col text-center space-y-1">
            <h2>{{ horse.name }}</h2>
            <Text v-if="horse.born_at">
                {{ format(horse.born_at, 'YYYY-MM-DD') }} {{ horse.born_at && `(${getAge(horse.born_at)})` }}</Text
            >
            <Text>{{ horse.breed }}</Text>
        </div>
    </div>

    <AddVetModal :horse="horse" v-model:is-open="openAddVetModal" />
</template>

<script setup>
import { computed, ref } from 'vue'
import { format } from '@/lib/helpers/time'
import { getAge } from '@/lib/helpers/time'

import AddLink from '@/components/buttons/AddLink.vue'
import Text from '@/components/Text.vue'
import TooltipElement from '@/components/Tooltip.vue'
import Avatar from '@/components/Avatar.vue'
import AddVetModal from '@/components/modals/AddVetModal.vue'

const props = defineProps({
    horse: { type: Object, required: true }, // Horse object
    readOnly: { type: Boolean, default: false }, // Set if avatar can be edited
    small: { type: Boolean, default: false }, // Small size version
    displayOwners: { type: Boolean, default: true },
})

const openAddVetModal = ref(false)

const owners = computed(() =>
    props.horse?.users
        ? props.horse?.users
              .filter((user) => user.is_owner)
              .map((owner) => {
                  return `${owner.first_name} ${owner.last_name}`
              })
              .join(' / ')
        : ''
)

const veterinarians = computed(() =>
    props.horse?.users
        ? props.horse?.users
              .filter((user) => user.is_vet)
              .map((vet) => {
                  return `${vet.first_name} ${vet.last_name}`
              })
              .join(' / ')
        : ''
)

const emit = defineEmits(['update:avatar'])

const updateAvatar = (file) => {
    emit('update:avatar', file)
}
</script>

<script>
export default {
    inheritAttrs: false,
}
</script>
