const white = 'rgb(255 255 255)'

/** @type {import('tailwindcss').Config} */
export default {
    darkMode: 'class', // This enables dark mode based on the presence of the "dark" class in the HTML tag
    content: ['./public/**/*.html', './src/**/*.{vue,js,ts,jsx,tsx,woff,woff2}'],
    theme: {
        extend: {
            backgroundSize: {
                'size-200': '200% 200%',
            },
            backgroundPosition: {
                'pos-0': '0% 0%',
                'pos-100': '100% 100%',
            },
            padding: {
                header: '52px',
                title: '36px',
                banner: '44px',
            },
            height: {
                104: '26rem',
                112: '28rem',
                128: '32rem',
            },
            fontSize: {
                'xxs': '10px',
                //
                // 'xs': not changed
                // small //14
                'sm': [
                    '14px',
                    {
                        letterSpacing: '0.6px',
                        lineHeight: '19px',
                    },
                ],
                // h4 medium
                'base': [
                    '15px',
                    {
                        letterSpacing: '-0.23px',
                        lineHeight: '20px',
                    },
                ],
                // h3
                'lg': [
                    '17px',
                    {
                        letterSpacing: '-0.43px',
                        lineHeight: '22px',
                    },
                ],
                // h2
                'xl': [
                    '22px',
                    {
                        letterSpacing: '-0.22px',
                        lineHeight: '26px',
                    },
                ],
                // h1
                '2xl': [
                    '26px',
                    {
                        letterSpacing: '0.22px',
                        lineHeight: '32px',
                    },
                ],
            },
            colors: {
                'dark': {
                    base: '#1C1D1F',
                    light: '#303032',
                    lighter: '#3D3E42',
                    lightest: '#4B4C50',

                    // base: '#1C1D1F',
                    // // light: '#2C2D30',
                    // light: '#2C2C2D',
                    // // lighter: '#3A3A3C',
                    // lighter: '#3D3E42',
                    // lightest: 'rgba(255,255,255,0.12)',
                },
                'text': {
                    base: white,
                    darker: '#FFFFFFA6', // 'rgba(255, 255, 255, 0.65)',
                    gray: '#848890',
                    black: '#1C1D1F',
                },
                'border': 'rgba(255, 255, 255, 0.14)',
                'brand': {
                    // base: '#4d5eff',
                    base: '#5060f1',
                    yellow: '#FC3',
                    gold: '#ffc400',
                    teal: '#66DDDD',
                    red: '#E46',
                    error: 'rgba(235,58,19,0.36)',
                },
                'state': {
                    red: '#e6194b',
                    green: '#3cb44b',
                    orange: '#EF9654',
                    yellow: '#FC3',
                },
                'gaits': {
                    canter: '#6655FF', //'#D64081',//'#65f',//'#D64081',//'#6655FF',//#D64081
                    trot: '#5B8FFF', //'#F46A63',//'#CC87EE',//'#65F',//'#4093FC',//#65F
                    walk: '#17CDCE', //'#6655FF',//'#028EFD',//'#028EFD',//'#2EAFCC',//#028EFD
                    standing: '#A9D3CC', //'#5B8FFF',//'#89C2F9',//'#3BC2A3',//'#3BC2A3',//#3BC2A3
                    all: '#CC87EE',
                },
                'polar': {
                    zone1: '#c2caca', //VERY LIGHT => HEART RATE ZONE 1: 30–60% OF HRMAX
                    zone2: '#46c7ee', //LIGHT => HEART RATE ZONE 2: 60–70% OF HRMAX
                    zone3: '#6acc2b', //MODERATE => HEART RATE ZONE 3: 70–80% OF HRMAX
                    zone4: '#f9bf1c', //HARD => HEART RATE ZONE 4: 80–90% OF HRMAX
                    zone5: '#de0f5b', //MAXIMUM => HEART RATE ZONE 5: 90–100% OF HRMAX
                },
                'stoodOut': '#F46A63',
                'avgJumps': '#CC87EE',
                'strikePower': '#D64081',
                'jumps': [
                    '#e6194b',
                    '#3cb44b',
                    '#ffe119',
                    '#4363d8',
                    '#f58231',
                    '#911eb4',
                    '#46f0f0',
                    '#f032e6',
                    '#bcf60c',
                    '#fabebe',
                    '#008080',
                    '#e6beff',
                    '#9a6324',
                    '#fffac8',
                    '#800000',
                    '#aaffc3',
                    '#808000',
                    '#ffd8b1',
                    '#000075',
                    '#808080',
                    '#ffffff',
                    '#000000',
                    //add the same colors twice
                    '#e6194b',
                    '#3cb44b',
                    '#ffe119',
                    '#4363d8',
                    '#f58231',
                    '#911eb4',
                    '#46f0f0',
                    '#f032e6',
                    '#bcf60c',
                    '#fabebe',
                    '#008080',
                    '#e6beff',
                    '#9a6324',
                    '#fffac8',
                    '#800000',
                    '#aaffc3',
                    '#808000',
                    '#ffd8b1',
                    '#000075',
                    '#808080',
                    '#ffffff',
                    '#000000',
                ],
                'jumpsAnalysis': ['#f72586', '#4bc9f1', '#fde441', '#80ee98'],
                'direction': {
                    left: '#4bc9f1',
                    right: '#fde441',
                },
                'banner': {
                    info: {
                        text: white,
                        background: '#5060f1',
                    },
                    warning: {
                        text: white,
                        background: '#FC3',
                    },
                    danger: {
                        text: white,
                        background: '#E46',
                    },
                },
                'button': {
                    base: '#5060f1',
                    warning: '#FC3',
                    danger: '#E46',
                    dark: {
                        lighter: '#3D3E42',
                        lightest: 'rgba(255,255,255,0.12)',
                    },
                },
                'gradient': [
                    '#d2ff28',
                    '#d4ea2f',
                    '#d7d535',
                    '#d7c13a',
                    '#dcab42',
                    '#de9749',
                    '#e1814f',
                    '#e36c56',
                    '#e6575b',
                    '#e74262',
                ],
                'discipline': {
                    'jumping': ['#5B3479', '#310057'],
                    'dressage': ['#3339EB', '#052C71'],
                    'cross': ['#5CD66A', '#096006'],
                    'endurance': ['#FFC300', '#EDD77D'],
                    'racing': ['#FF0000', '#571305'],
                    'reining': ['#FF7105', '#D1A366'],
                    'barrel-racing': ['#FF7105', '#D1A366'],
                    'gymnastics': ['#FF61E5', '#F5D1F2'],
                    'flat-work': ['#00FFF7', '#0090AD'],
                    'outriding': ['#02CF9F', '#003319'],
                    'default': ['#6655FF', 'rgba(255,255,255,0.12)'],
                },
                // START Colors variables for PRIMEVUE
                'primary': 'rgb(var(--primary))',
                'primary-inverse': 'rgb(var(--primary-inverse))',
                'primary-hover': 'rgb(var(--primary-hover))',
                'primary-active-color': 'rgb(var(--primary-active-color))',

                'primary-highlight': 'rgb(var(--primary)/var(--primary-highlight-opacity))',
                'primary-highlight-inverse': 'rgb(var(--primary-highlight-inverse))',
                'primary-highlight-hover': 'rgb(var(--primary)/var(--primary-highlight-hover-opacity))',

                'primary-50': 'rgb(var(--primary-50))',
                'primary-100': 'rgb(var(--primary-100))',
                'primary-200': 'rgb(var(--primary-200))',
                'primary-300': 'rgb(var(--primary-300))',
                'primary-400': 'rgb(var(--primary-400))',
                'primary-500': 'rgb(var(--primary-500))',
                'primary-600': 'rgb(var(--primary-600))',
                'primary-700': 'rgb(var(--primary-700))',
                'primary-800': 'rgb(var(--primary-800))',
                'primary-900': 'rgb(var(--primary-900))',
                'primary-950': 'rgb(var(--primary-950))',

                'surface-0': 'rgb(var(--surface-0))',
                'surface-50': 'rgb(var(--surface-50))',
                'surface-100': 'rgb(var(--surface-100))',
                'surface-200': 'rgb(var(--surface-200))',
                'surface-300': 'rgb(var(--surface-300))',
                'surface-400': 'rgb(var(--surface-400))',
                'surface-500': 'rgb(var(--surface-500))',
                'surface-600': 'rgb(var(--surface-600))',
                'surface-700': 'rgb(var(--surface-700))',
                'surface-800': 'rgb(var(--surface-800))',
                'surface-900': 'rgb(var(--surface-900))',
                'surface-950': 'rgb(var(--surface-950))',
                // END Colors variables for PRIMEVUE
            },
        },
    },
    plugins: [import('@tailwindcss/forms'), import('@tailwindcss/aspect-ratio')],
}
