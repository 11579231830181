import Storage from '@/lib/memory/Storage'
import { Share } from '@capacitor/share'
import { toPng, toBlob } from 'html-to-image'
import { Directory, Filesystem } from '@capacitor/filesystem'

export const exportAndSaveCsv = (arrData, filename = 'export') => {
    const fileParams = 'data:text/csv;charset=utf-8,%EF%BB%BF'
    let csvContent = [Object.keys(arrData[0]).join(';'), ...arrData.map((item) => Object.values(item).join(';'))]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

    const data = fileParams + encodeURIComponent(csvContent)
    const link = document.createElement('a')
    const filenameWithExtension = filename + '.csv'
    link.setAttribute('href', data)
    link.setAttribute('target', '_blank')
    link.setAttribute('download', filenameWithExtension)
    link.click()
    saveFileToStorage(csvContent, filenameWithExtension)
}

const saveFileToStorage = async (strData, filename = 'export') => {
    const path = null
    const storageObject = new Storage()
    storageObject.documentsDirectory()
    await storageObject.writeFile(path, filename, strData)
    return await storageObject.readFile(path, filename)
}

export const shareContent = async (node, filename = 'AlogoPerformanceTrace') => {
    const filenameWithExtension = `${filename}.png`
    if ((await Share.canShare()) && navigator.share) {
        const base64Data = await toPng(node)
        await Filesystem.writeFile({
            path: filenameWithExtension,
            data: base64Data,
            directory: Directory.Cache,
        })
        const uriResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: filenameWithExtension,
        })
        await Share.share({
            title: 'Alogo Performance',
            text: 'Alogo Performance',
            url: uriResult.uri,
        })
    } else {
        const blob = await toBlob(node)
        // Créer un lien de téléchargement
        if (navigator.msSaveBlob) {
            // Internet Explorer
            navigator.msSaveBlob(blob, filenameWithExtension)
        } else if ('download' in HTMLAnchorElement.prototype) {
            // Modern browsers
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filenameWithExtension
            link.click()
            window.URL.revokeObjectURL(link.href)
        } else {
            console.error("Téléchargement d'image non pris en charge.")
        }
    }
}
