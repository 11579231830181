<template>
    <div class="flex">
        <slot name="icon" />

        <div
            class="flex flex-col"
            :class="[
                ...(space === 0 ? ['space-y-0'] : []),
                ...(space === 1 ? ['space-y-1'] : []),
                ...(space === 2 ? ['space-y-2'] : []),
                ...(small ? ['text-sm'] : []),
                ...(high ? ['text-base'] : []),
                ...(column ? ['items-center'] : []),
            ]"
        >
            <div v-if="title !== false" class="text-text-darker whitespace-nowrap" :class="customTitleClass">
                {{ title }}
            </div>

            <div :class="valueDiff !== null ? 'flex flex-col items-end' : ''">
                <div :class="{ 'inline-flex space-x-2': minMaxInline, 'flex flex-col': !minMaxInline }" class="">
                    <component :is="small ? 'h4' : high ? 'h2' : 'h3'">
                        <div v-if="!allowZero && isNullOrZero(formatDisplayedValue.value)">-</div>
                        <div v-else class="whitespace-nowrap flex" :class="column ? 'flex-col items-center' : ''">
                            <div :style="`color: ${color ? color : 'inherit'}`">{{ formatDisplayedValue.value }}</div>

                            <div
                                v-if="formatDisplayedValue.unit"
                                class="uppercase text-text-darker tracking-wide flex"
                                :class="{
                                    'text-xs': small,
                                    'text-base': !small && high,
                                    'text-sm': !small && !high,
                                    'items-end': !column,
                                    'ml-1': !column,
                                }"
                            >
                                {{ formatDisplayedValue.unit }}
                            </div>
                        </div>
                    </component>

                    <div v-if="max !== null || min !== null" class="flex items-center">
                        <div class="flex flex-col">
                            <div v-if="max !== null" class="flex">
                                <RowValue
                                    :title="$t('metric.max')"
                                    :titlePosition="minMaxTitlePosition"
                                    :value="max"
                                    :unit="unit"
                                    :userUnit="formatDisplayedValue.unit"
                                    :forceUnit="true"
                                    :displayUnit="false"
                                    :decimals="decimals"
                                />
                            </div>

                            <div v-if="min !== null" class="flex">
                                <RowValue
                                    :title="$t('metric.min')"
                                    :titlePosition="minMaxTitlePosition"
                                    :value="min"
                                    :unit="unit"
                                    :userUnit="formatDisplayedValue.unit"
                                    :forceUnit="true"
                                    :displayUnit="false"
                                    :decimals="decimals"
                                />
                            </div>
                        </div>
                    </div>

                    <slot name="details" />
                </div>

                <span
                    v-if="valueDiff !== null"
                    class="text-xs inline-block self-end uppercase"
                    :style="`color: ${colorDiff};`"
                >
                    {{ displayedValueDiff }}
                    <span class="text-text-darker">{{ formatDisplayedValueDiff.unit }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { convertValueUnit } from '@/lib/helpers/convert'
import { isNullOrZero } from '@/lib/helpers/numeric'
import tailwind from '@/lib/tailwind'

import RowValue from '@/components/RowValue.vue'

const props = defineProps({
    value: { type: [String, Number], default: '' },
    decimals: { type: Number, default: undefined },
    unit: { type: String, default: '' },
    userUnit: { type: String, default: '' },
    forceUnit: { type: Boolean, default: false },
    title: { type: [String, Boolean], default: '' },
    customTitleClass: { type: String, default: undefined },
    space: { type: Number, default: 2 },
    allowZero: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    high: { type: Boolean, default: false },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    minMaxInline: { type: Boolean, default: true },
    minMaxTitlePosition: { type: String, default: undefined },
    valueDiff: { type: [String, Number], default: null },
    unitDiff: { type: String, default: '' },
    signDiff: { type: Boolean, default: true },
    colorDiff: { type: String, default: tailwind.theme.colors.brand.base },
    column: { type: Boolean, default: false },
    color: { type: String, default: null },
})
const formatDisplayedValue = computed(() =>
    convertValueUnit(props.value, props.unit, props.userUnit, props.decimals, props.forceUnit)
)

const formatDisplayedValueDiff = computed(() =>
    convertValueUnit(props.valueDiff, props.unitDiff, props.userUnit, props.decimals, props.forceUnit)
)

const displayedValueDiff = computed(() =>
    props.signDiff
        ? formatDisplayedValueDiff.value?.value.toString().includes('-')
            ? formatDisplayedValueDiff.value?.value
            : '+' + formatDisplayedValueDiff.value?.value
        : formatDisplayedValueDiff.value?.value
)
</script>
