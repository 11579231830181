import { createApp } from 'vue'
import AppComponent from './app.vue'
// import { App } from '@capacitor/app'
import router from '@/router'
import store from '@/store'
import sensor from '@/sensor'
import { initSentry } from '@/sentry'
import { i18n } from '@/i18n'
import mapboxgl from 'mapbox-gl'
import { IonicVue } from '@ionic/vue'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import BannerPlugin from '@/plugins/banner'
import PrimeVue from 'primevue/config'
import Aura from '@/presets/aura' //import preset

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)
import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'
/* Core CSS required for Ionic components to work properly */
// import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
// import '@ionic/vue/css/normalize.css'
// import '@ionic/vue/css/structure.css'
// import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css'
// import '@ionic/vue/css/float-elements.css'
// import '@ionic/vue/css/text-alignment.css'
// import '@ionic/vue/css/text-transformation.css'
// import '@ionic/vue/css/flex-utils.css'
// import '@ionic/vue/css/display.css'

import './assets/css/app.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import './assets/fonts/inter.css'
import './assets/css/ion/base.css'
import './assets/css/ion/toast.css'
import './assets/css/ion/modal.css'
import './assets/css/animations.css'

if (Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.hide()
}
if (Capacitor.isPluginAvailable('SplashScreen')) {
    SplashScreen.show()
}

const getConfig = () => {
    let config = {
        mode: 'ios',
        swipeBackEnabled: true,
    }

    // if (isPlatform('iphone')) {
    //     config = {
    //         ...config,
    //         backButtonText: 'Previous',
    //     }
    // }

    return config
}

// Bootstrap application services.
mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_TOKEN
// StatusBar.setOverlaysWebView({ overlay: true }) //Android only

// Store the cordova info when the device is ready.
document.addEventListener('deviceready', () => store.commit('device/cordova_info', window.device), false)

// Deep link to open mobile app if installed
// See https://capacitorjs.com/docs/guides/deep-links
CapacitorApp.addListener('appUrlOpen', (event) => {
    console.log('### DEEPLINK ###', event)
    const slug = event.url.split('app.alogo.io').pop()
    // We only push to the route if there is a slug present
    if (slug) {
        const paramArr = slug.slice(slug.indexOf('?') + 1).split('&')
        const query = {}
        paramArr.map((param) => {
            const [key, val] = param.split('=')
            query[key] = decodeURIComponent(val)
        })
        router.push({
            path: slug,
            query: query,
        })
    } else {
        router.push({ name: 'index' })
    }
})

// Create the vue 3 application.
const app = createApp(AppComponent)
    .use(router)
    .use(store)
    .use(i18n)
    .use(IonicVue, getConfig())
    .use(BannerPlugin)
    .use(sensor)
    .use(PrimeVue, {
        unstyled: true,
        pt: Aura, //apply preset
    })

app.config.performance = !import.meta.env.PROD

// finally on tablet we want to show the web version of the app
// const isBuildForMobile = false
const isBuildForMobile = import.meta.env.VITE_APP_MOBILE === 'true' || import.meta.env.VITE_APP_MOBILE === true

const isMobile = () => {
    if (isBuildForMobile) {
        return true
    } else if (window.innerWidth >= 1024) {
        return false
        // media queries => md
    } else {
        return true
    }
}
app.config.globalProperties.$isMobile = isMobile()
app.provide('isMobile', app.config.globalProperties.$isMobile)
app.config.globalProperties.$isBuildForMobile = isBuildForMobile
app.provide('isBuildForMobile', isBuildForMobile)

let timer = undefined
const onResize = (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
        let vh = window.innerHeight * 0.01
        // console.log('### vh', vh, document.documentElement.clientHeight, window.innerHeight)
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        // console.log('###### onResize ###### ', window.innerWidth, window.innerHeight)
        if (app.config.globalProperties.$isMobile !== isMobile()) {
            return location.reload()
        }
    }, 100)
}

// let routerIsReady = false
initSentry(app, router, isBuildForMobile)

// Initiate the application when the router is ready.
router.isReady().then(() => {
    // routerIsReady = true
    app.mount('#app')
    onResize()
    window.addEventListener('resize', onResize)
})
