import * as Sentry from '@sentry/vue'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { Offline as OfflineIntegration, CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

const dsn = 'https://d5087e7d23fb4178bce6d062d36dc5aa@o923658.ingest.sentry.io/4504366037204992'
const sendSentryLogs = import.meta.env.PROD
const viteEnv = import.meta.env.VITE_APP_ENVIRONMENT

const initSentry = (app, router, isBuildForMobile) => {
    let integrations = []
    if (sendSentryLogs) {
        integrations = [
            new TracingIntegrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new CaptureConsoleIntegration({
                // array of methods that should be captured
                // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['warn', 'error'],
            }),
            new OfflineIntegration({
                // limit how many events will be localled saved. Defaults to 30.
                maxStoredEvents: 20,
            }),
            // todo 20210721 David comment still a WIP and not really functionnal
            // new SentryRRWeb({
            //     // ...options
            // }),
        ]
    }

    Sentry.init({
        app,
        dsn,
        integrations,
        beforeSend(event, hint) {
            if (
                /Loading chunk [\d]+ failed/.test(event.message) ||
                /Loading chunk [\d]+ failed/.test(hint.originalException)
            ) {
                window.location.reload()
                return null
            }
            // https://sentry.io/organizations/alogo/issues/3470202134/
            if (
                /Failed to fetch dynamically imported module/.test(event.message) ||
                /Failed to fetch dynamically imported module/.test(hint.originalException)
            ) {
                window.location.reload()
                return null
            }

            // Modify the event here
            if (!sendSentryLogs) {
                // if (routerIsReady) {
                console.error(
                    ['local', 'development'].includes(viteEnv) ? hint.originalException ?? event.message : event
                )
                // console.error(event.message)
                // }
                // Don't send the logs
                return null
            }
            return event
        },
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.3,
        environment: import.meta.env.VITE_APP_ENVIRONMENT,
        trackComponents: true,
        autoSessionTracking: true,
        initialScope: { tags: { isBuildForMobile: isBuildForMobile, platform: Capacitor.getPlatform() } },
        release: APP_NAME + '@' + APP_VERSION,
    })
}

export { initSentry }
