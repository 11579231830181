<template>
    <div v-if="editing" class="flex w-full space-x-1">
        <Input v-bind="$attrs" @input:leave="() => setEditing(false)" autofocus />
        <!-- <XLightIcon class="h-4 w-4 cursor-pointer" @click="editing = false" /> -->
    </div>
    <div v-else class="flex space-x-1">
        <div @dblclick="() => setEditing(true)">
            <slot />
        </div>

        <div v-if="$slots.icon">
            <slot name="icon" />
        </div>
        <div v-else-if="!iconless && editable">
            <PencilSquareIcon class="h-4 w-4 cursor-pointer text-brand-base" @click="() => setEditing(true)" />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Input from '@/components/form/Input.vue'
import { PencilSquareIcon } from '@heroicons/vue/24/outline'
// import XLightIcon from '@/components/icons/XLightIcon.vue'

const props = defineProps({
    edit: { type: Boolean, default: false },
    iconless: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
})
const emit = defineEmits(['input:leave'])
const editing = ref(false)

const setEditing = (isEditing) => {
    if (props.editable && isEditing) {
        editing.value = true
        return
    }
    if (editing.value && !isEditing) {
        emit('input:leave')
    }
    editing.value = false
}
onMounted(() => {
    setEditing(props.edit)
})
</script>

<script>
// use normal <script> to declare options
export default {
    inheritAttrs: false,
}
</script>
