<template>
    <div id="root-fullscreen" />
    <!-- PAGE HEADER -->
    <!-- The content will be automatically injected here by components/Header.vue -->
    <div id="header" class="z-10" />

    <ProgressBar v-show="isLoading" mode="indeterminate" style="height: 6px"></ProgressBar>

    <!-- PAGE CONTENT -->
    <ion-content
        id="topWindow"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
        :style="{ transform: `translateX(${translateX}px)` }"
        :fullscreen="true"
        class="h-full"
        :class="{
            'flex items-center justify-center ': !hasTabs || !$store.state.auth.initialCheckDone,
            'login-background-mobile': $route.meta.layout === 'auth' && $isMobile,
            'login-background-desktop': $route.meta.layout === 'auth' && !$isMobile,
            'opacity-10': Math.abs(translateX) > pixelsSwipeThreshold,
            'opacity-70': Math.abs(translateX) > 0 && Math.abs(translateX) < pixelsSwipeThreshold,
        }"
    >
        <div v-if="!$store.state.auth.initialCheckDone" class="flex h-full w-full items-center justify-center">
            <Loader :size="100" />
        </div>
        <div v-else class="h-full w-full">
            <div v-if="$isMobile && hasTabs" class="p-4">
                <router-view name="mobile" />
            </div>

            <Navbar v-else-if="hasTabs">
                <div class="p-4">
                    <router-view />
                </div>
            </Navbar>

            <div v-else class="p-4">
                <router-view />
            </div>

            <!-- !!! here it is mandatory to only load the modal when logged in because the may cause an infinite loop -->
            <ModalsGroup v-if="$store.getters['auth/loggedIn']" />
        </div>
    </ion-content>
    <!-- PAGE FOOTER (TABS) FOR MOBILE APP -->
    <ion-footer
        ref="tabs"
        v-if="$isMobile && hasTabs && $store.state.auth.initialCheckDone && !keyboardWillVisible"
        :class="{ 'pb-4 bg-dark-light': $store.getters['device/bottomPadding'] === 'small' }"
    >
        <ion-toolbar class="ion-toolbar-light">
            <div class="flex items-center justify-center h-20">
                <router-link
                    tag="div"
                    :to="{ name: 'sessions.index' }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'sessions' }"
                    >
                        <ListBulletIcon class="w-6 h-6" />
                        <div>{{ $t('session.title', 2) }}</div>
                    </div>
                </router-link>

                <router-link
                    tag="div"
                    :to="{ name: 'horses.index' }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'horses' }"
                    >
                        <DressageIcon class="w-9 -mb-1 -mt-2" />
                        <div>{{ $t('horse.title', 2) }}</div>
                    </div>
                </router-link>

                <router-link
                    tag="div"
                    :to="{
                        name: $sensor.current.isRecording || $sensor.current.isPaused ? 'record.live' : 'record.index',
                    }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'record' }"
                    >
                        <RecordIcon class="w-6 h-6" />

                        <div class="relative">
                            <DotIcon
                                v-if="$sensor.current.isRecording || $sensor.current.isPaused"
                                :color="tailwind.theme.colors.state.green"
                                :strokeColor="tailwind.theme.colors.dark.light"
                                class="flex float-right absolute -right-12 -top-11"
                                pulse
                            />
                            <DotIcon
                                v-else-if="$sensor.current.isMemoryMode"
                                :color="tailwind.theme.colors.state.yellow"
                                :strokeColor="tailwind.theme.colors.dark.light"
                                class="flex float-right absolute -right-12 -top-11"
                                pulse
                            />
                        </div>

                        <div>{{ $t('record.title') }}</div>
                    </div>
                </router-link>
                <router-link
                    v-if="$store.state.auth?.user?.job_name === 'coach'"
                    tag="div"
                    :to="{ name: 'coaching.sessions.index' }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'coaching' }"
                    >
                        <PresentationChartLineIcon class="w-6 h-6" />
                        <div>{{ $t('coaching.title') }}</div>
                    </div>
                </router-link>
                <router-link
                    v-else
                    tag="div"
                    :to="{ name: 'protocols.index' }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'protocols' }"
                    >
                        <NewspaperIcon class="w-6 h-6" />
                        <div>{{ $t('protocol.title', 2) }}</div>
                    </div>
                </router-link>
                <!-- <router-link
                    v-else
                    tag="div"
                    :to="{ name: 'teams.sessions.index' }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'teams' }"
                    >
                        <UsersIcon class="w-6 h-6" />
                        <div>{{ $t('team.title', 2) }}</div>
                    </div>
                </router-link> -->
                <router-link
                    tag="div"
                    :to="{ name: 'settings.index' }"
                    class="h-full flex-grow flex items-center justify-center"
                >
                    <div
                        class="flex flex-col justify-center items-center text-xs space-y-1 font-light"
                        :class="{ 'text-brand-base': currentRouteLayout === 'settings' }"
                    >
                        <SettingsIcon class="w-6 h-6" />
                        <div>{{ $t('menu.setting', 2) }}</div>
                    </div>
                </router-link>
            </div>
        </ion-toolbar>
    </ion-footer>
</template>

<script setup>
import { computed, ref, provide, onMounted, onUnmounted, watchEffect, watch, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Geolocation } from '@capacitor/geolocation'
import { Network } from '@capacitor/network'
import { isPlatform, IonContent, IonFooter, IonToolbar, IonHeader, IonTitle } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'
import { Capacitor } from '@capacitor/core'
import axios from '@/lib/API/client'
import * as Sentry from '@sentry/vue'

import tailwind from '@/lib/tailwind'
import Memory from '@/lib/memory'
import useBanners from '@/lib/hooks/use-banners'
import useMetas from '@/lib/hooks/use-metas'
import ProgressBar from 'primevue/progressbar'

import { ListBulletIcon, UsersIcon, NewspaperIcon, PresentationChartLineIcon } from '@heroicons/vue/24/solid'
import DressageIcon from '@/components/icons/DressageIcon.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import RecordIcon from '@/components/icons/RecordIcon.vue'
import DotIcon from '@/components/icons/DotIcon.vue'
import Loader from '@/components/Loader.vue'
import { Toast } from '@/components/ion/Toast.vue'
import ModalsGroup from '@/components/modals/ModalsGroup.vue'
import Navbar from '@/layouts/Navbar.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { startBannersWatcher, show: showBanner, hide: hideBanner } = useBanners()
const { t, locale } = useI18n()
const { metas } = useMetas()
const header = ref(undefined)
const tabs = ref(undefined)
const isLoading = ref(false)

const keyboardWillVisible = ref(false)
const keyboardVisible = ref(false)

const startX = ref(null)
const startY = ref(null)
const isSwiping = ref(false)
const translateX = ref(0)
const swipeDirection = ref(null)
const pixelsSwipeThreshold = 100

const handleTouchStart = (event) => {
    startX.value = event.touches[0].clientX
    startY.value = event.touches[0].clientY
    isSwiping.value = true
}

const handleTouchMove = (event) => {
    if (startX.value === null || startY.value === null) return

    const currentX = event.touches[0].clientX
    const currentY = event.touches[0].clientY
    const deltaX = currentX - startX.value
    const deltaY = currentY - startY.value

    const screenWidth = window.innerWidth
    const swipeEdgeThreshold = screenWidth * 0.1

    // Seuil pour différencier un balayage horizontal d'un défilement vertical
    const swipeThreshold = 30

    if (!swipeDirection.value) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            if (Math.abs(deltaX) > swipeThreshold) {
                if (startX.value < swipeEdgeThreshold) {
                    swipeDirection.value = 'right' // Balayage de gauche à droite
                } else if (screenWidth - startX.value < swipeEdgeThreshold) {
                    swipeDirection.value = 'left' // Balayage de droite à gauche
                } else {
                    swipeDirection.value = null
                }
            }
        } else {
            swipeDirection.value = 'scroll'
        }
    }

    // Si la direction du balayage est détectée
    if (swipeDirection.value && swipeDirection.value !== 'scroll') {
        event.preventDefault() // Empêcher le défilement vertical pendant le balayage horizontal
        if (Math.abs(deltaX) > pixelsSwipeThreshold) {
            translateX.value = deltaX > 0 ? pixelsSwipeThreshold + 1 : -pixelsSwipeThreshold - 1
        } else {
            translateX.value = deltaX
        }
    }
}

const handleTouchEnd = (event) => {
    if (!startX.value || !swipeDirection.value) return

    // Signifie que le début était dans la bordure
    if (translateX.value > pixelsSwipeThreshold && swipeDirection.value === 'right') {
        router.back()
    } else if (translateX.value < -pixelsSwipeThreshold && swipeDirection.value === 'left') {
        router.forward()
    }

    startX.value = null
    startY.value = null
    isSwiping.value = false
    swipeDirection.value = null
    translateX.value = 0
}

const sensor = inject('sensor')
const isMobile = inject('isMobile')

Network.addListener('networkStatusChange', (status) => {
    // console.log('Network status changed', status)
    const bannerId = 'youAreOffline'
    if (!status?.connected) {
        showBanner({
            id: bannerId,
            name: bannerId,
            level: 'danger',
            text: t('banner.you-are-offline'),
            shortText: t('banner.you-are-offline'),
        })
    } else {
        hideBanner(bannerId)
    }
})

if (Capacitor.isPluginAvailable('Keyboard')) {
    Keyboard.addListener('keyboardWillShow', (info) => {
        // console.log('keyboard will show with height:', info.keyboardHeight)
        keyboardWillVisible.value = true
    })

    Keyboard.addListener('keyboardDidShow', (info) => {
        // console.log('keyboard did show with height:', info.keyboardHeight)
        keyboardVisible.value = true
        const activeElement = document.activeElement
        const inputs = ['input', 'textarea']
        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            const scrollableParent = activeElement.closest('.scrollableContent')
            if (scrollableParent) {
                scrollableParent.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    })

    Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard will hide')
        keyboardWillVisible.value = false
    })

    Keyboard.addListener('keyboardDidHide', () => {
        // console.log('keyboard did hide')
        keyboardVisible.value = false
    })
}

const currentRouteLayout = computed(() => router.currentRoute.value?.meta?.layout)

// Determines if the tabs should be shown or not based
// on the route `tabs` parameter.
const hasTabs = computed(() => route.meta.tabs ?? true)
const hasHeader = ref(false)

// Provide the application with configuration values:
provide('layout-configuration', { hasTabs, hasHeader, tabs, header, keyboardVisible })
provide('isLoading', isLoading)

watchEffect((onInvalidate) => {
    if (header.value) {
        const observer = new MutationObserver(() => {
            hasHeader.value = header.value?.innerHTML !== ''
        })
        observer.observe(header.value, { subtree: true, childList: true })
        onInvalidate(() => observer.disconnect())
    }
})

onMounted(async () => {
    // Initiate the authentication logic.
    await store.dispatch('auth/initialCheck')

    const query = Object.assign({}, route.query)
    // Sharing
    if (route.query['team-sharing'] === 'success') {
        delete query['team-sharing']
        Toast.success(t('team.team-joined'))
    }
    if (route.query['team-sharing'] === 'failed') {
        delete query['team-sharing']
        Toast.warning(t('error.there-was-error'))
    }
    if (route.query['session-sharing'] === 'success') {
        delete query['session-sharing']
        Toast.success(t('session.session-joined'))
    }
    if (route.query['session-sharing'] === 'failed') {
        delete query['session-sharing']
        Toast.warning(t('error.there-was-error'))
    }
    if (route.query['horse-sharing'] === 'success') {
        delete query['horse-sharing']
        Toast.success(t('horse.horse-joined'))
    }
    if (route.query['horse-sharing'] === 'failed') {
        delete query['horse-sharing']
        Toast.warning(t('error.there-was-error'))
    }
    if (route.query['email'] === 'verified') {
        delete query['email']
        Toast.success(t('login.verified'))
    }
    if (route.query['email'] === 'unverified') {
        delete query['email']
        Toast.success(t('login.email-validation-link-expired'))
    }
    router.replace({ query })

    watch(
        locale,
        () => {
            const bluetoothStrings = {
                scanning: t('sensor.scanning'), //'Scanning...'
                cancel: t('sensor.cancel'), //'Cancel'
                availableDevices: t('sensor.availableDevices'), //'Available devices'
                noDeviceFound: t('sensor.noDeviceFound'), //'No device found'
            }
            sensor.bluetooth.setDisplayStrings(bluetoothStrings)
        },
        { immediate: true }
    )
})

//scroll to the top on each route changes
watch(router.currentRoute, () => {
    let scrollContent = document.getElementById('topWindow')

    if (scrollContent && typeof scrollContent.scrollToTop === 'function' && !route.meta.dontScrollTop) {
        scrollContent.scrollToTop()
    }
})

watch(
    () => store.getters['auth/loggedIn'],
    async (isLoggedIn) => {
        if (isLoggedIn) {
            // Explicit alert for collect location data (Android)
            if (isPlatform('android') && isMobile) {
                const isAndroidPopupDisplayed = await Memory.get('androidPopupDisplayed')

                if (!isAndroidPopupDisplayed) {
                    alert(t('location.androidalert'))
                    Memory.set('androidPopupDisplayed', true)
                }
            }
            //delay the execution of the watcher to be sure the user locale is correctly loaded
            setTimeout(() => {
                startBannersWatcher()
                setTimeout(() => {
                    if (
                        store.state.auth?.user?.subscription?.active &&
                        store.state.auth?.user?.subscription?.code === 'temporary' &&
                        route?.params?.sessionId !== metas.value?.['session-demo-id']
                    ) {
                        const bannerId = 'freePremiumAccess'
                        showBanner({
                            id: bannerId,
                            name: bannerId,
                            level: 'info',
                            text: t('banner.free-premium-access-text'),
                            shortText: t('banner.free-premium-access-shortText'),
                            buttonText: t('banner.free-premium-access-button'),
                            buttonLink: () => {
                                router.push({
                                    name: 'sessions.session',
                                    params: { sessionId: metas.value?.['session-demo-id'] },
                                })
                                hideBanner(bannerId)
                            },
                        })
                    }
                }, 1000)
            }, 3000)
        }

        if (!isPlatform('android') && !isPlatform('ios') && !sensor.bluetooth.hasWebAPIAvailble) {
            console.log('Bluetooth API not available in this browser.')
        } else {
            // will ask permissions on IOS and Android and nothing on web
            sensor.bluetooth.askPermissions(false)
        }

        if (typeof navigator === 'undefined' || !navigator.geolocation) {
            console.log('Geolocation API not available in this browser.')
        } else {
            Geolocation.requestPermissions(['location']).catch((error) => {
                console.log(`Geolocation API ${error.message}`)
            })
        }
    }
)

let timerCheckup
watch(
    () => sensor.current.isConnected,
    (isConnected, wasConnected) => {
        // console.log('isConnected, wasConnected', isConnected, wasConnected, sensor.current.isRecording)

        clearInterval(timerCheckup)
        if (isConnected && !wasConnected) {
            setTimeout(() => {
                timerCheckup = setInterval(() => {
                    if (
                        sensor.current.properties.amsMode === 5 &&
                        sensor.current.properties.gpsSpeed === 0 &&
                        sensor.current.properties.gpsDistance === 0
                    ) {
                        alert(t('sensor.gps-issue'))
                    }
                }, 5000)
            }, 5000)

            //wait some seconds to be sure all data are loaded properly
            setTimeout(() => {
                if (!sensor.current.isConnected) {
                    // if not connected do nothing
                    return
                }
                axios
                    .get(`/user/sensors`)
                    .then(({ data }) => {
                        try {
                            let requestData = {}
                            if (sensor.current.properties.name && isMobile) {
                                //only available on mobile
                                requestData['name'] = sensor.current.properties.name
                            }
                            if (sensor.current.properties.firmware) {
                                requestData['firmware_st'] = sensor.current.properties.firmware
                            }
                            if (sensor.current.properties.firmwareNRF) {
                                requestData['firmware_nordic'] = sensor.current.properties.firmwareNRF
                            }
                            if (sensor.current.properties.cumulativeTime) {
                                requestData['cumulative_time'] = sensor.current.properties.cumulativeTime
                            }
                            requestData['serial_id'] = sensor.current.properties.uuid
                            requestData['manufacturer'] = 'BSE'
                            requestData['type'] = 'Move pro'
                            requestData['last_transmission_at'] = Date.now()

                            const foundSensor = data.find((s) => s.serial_id === requestData.serial_id)

                            if (foundSensor !== undefined) {
                                if (!requestData['firmware_st']) {
                                    requestData['firmware_st'] = foundSensor.firmware_st
                                }
                                if (!requestData['firmware_nordic']) {
                                    requestData['firmware_nordic'] = foundSensor.firmware_nordic
                                }
                                axios.put(`sensors/${foundSensor.id}`, requestData)
                            } else {
                                axios.post('/sensors', requestData)
                            }
                        } catch (error) {
                            console.log('parse /user/sensors error', error)
                        }
                    })
                    .catch((error) => {
                        console.log('get /user/sensors error', error)
                        Sentry.captureMessage(error)
                    })
            }, 5000)
        }
    }
)

watch(
    () => sensor.current.isRecording,
    (isRecording, wasRecording) => {
        // console.log('isRecording, wasRecording', isRecording, wasRecording)
        if (isRecording && !wasRecording && sensor.current.isConnected) {
            // we do not redirect automatically to the live page because
            // we could go to the live page through the record button
            // console.log(
            //     'should redirect to record.live isRecording && !wasRecording && sensor.current.isConnected'
            // )
            // router.push({ name: 'record.live' })
            // } else if (!isRecording && wasRecording && sensor.current.isConnected && sensor.current.isStopped) {
        } else if (sensor.current.isStopped) {
            console.log('save meta')
            sensor.current.getSessionsList().then(() => {
                sensor.current.saveLiveSessionToLatestFile()
            })
        }
    }
)
</script>
